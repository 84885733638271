import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeLineService } from 'src/app/providers';
import { saveAs } from 'file-saver/FileSaver';
import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

// Local Storage Wrapper
import { LocalStorageWrapper } from 'src/app/etc/localStorageWrapper';
import { Location } from '@angular/common';

// Providers
import { OrderService } from '../../providers';

// Models
import Event from 'src/app/models/event.model';
import Widget from 'src/app/models/widget.model';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.html',
  styleUrls: ['./confirmation.scss']
})
export class ConfirmationPage implements OnInit {

  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;

  event: Event;
  widgetId: string;
  type: string;
  token: string;
  downloadInProgress: boolean = false;
  public widget: Widget;

  invoice: {
    orderNumber: null,
    id: null
  };
  tickets = [];
  streamPossible: boolean = false;
  getOrderExecuted: boolean = false;
  registration: boolean;

  constructor(
    private timeLineService: TimeLineService,
    public router: Router,
    public route: ActivatedRoute,
    private localStorageWrapper: LocalStorageWrapper,
    private location: Location,
    private orderService: OrderService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.widget = this.store.selectSnapshot(state => GlobalState.get(state, 'widgetId'));
    this.event = this.widget.eventId;

    window.parent.postMessage({
      changeStep: true
    }, '*');
    this.timeLineService.noPayment$.subscribe(value => {
      this.registration = value;
    })
    this.route.params.subscribe(_params => {
      this.timeLineService.updateTimeLine('confirmation');
      this.localStorageWrapper.init();
      this.widgetId = _params.id;
      this.type = _params.type;
      this.token = _params.token;
      this.location.go(`/${this.widgetId}/confirmation`);
    });
  }

  buyMoreTickets(): void {
    this.over();
  }

  over(): void {
    location.reload();
  }

  getOrderData() {
    if (this.token && !this.downloadInProgress) {
      this.downloadInProgress = true;
      this.orderService.getOrder(this.token).subscribe(_result => {
        this.getOrderExecuted = true;
        if (_result) {
          this.streamPossible = _result['streamPossible'];
          if (_result['streamPossible']) {
            this.invoice = _result['invoice'];
            this.tickets = _result['ticket'];
          }
        }
        this.downloadInProgress = false;
      }, err => {
        this.getOrderExecuted = true;
      });
    }
  }

  stream(_type, _data) {
    if (!this.downloadInProgress) {
      this.downloadInProgress = true;
      this.orderService.streamOrder(_type, _data['id'], this.token).subscribe(_result => {
        this.downloadInProgress = false;
        const blob = new Blob([_result], { type: 'application/pdf' });
        if (_type === 'orderId') {
          saveAs(blob, `invoice-${_data.orderNumber}`);
        } else if (_type === 'ticketId') {
          saveAs(blob, `ticket-${_data.ticketNumber}`);
        }
      });
    }
  }
}
