import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TimeLineService } from 'src/app/providers';
import { Subscription, Observable } from 'rxjs';
import { GlobalStateModel, GlobalState } from 'src/app/store/states/global.state';
import { Select } from '@ngxs/store';
import { Cart, GlobalStateObject } from 'src/app/models/type.definition';
import Widget from 'src/app/models/widget.model';

@Component({
  selector: 'app-m-timeline',
  templateUrl: './m-timeline.component.html',
  styleUrls: ['./m-timeline.component.scss']
})
export class MTimelineComponent implements OnInit, OnDestroy {
  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  @Input() stepDateExist: boolean;
  stepInsuranceExist: boolean = false;
  currentStep: string;
  currentIndex: number;
  subscription = new Subscription();
  noPayment: boolean;
  widgetId: Widget
  noCountdown: boolean;
  globalObject: GlobalStateObject;
  isNft: boolean;

  constructor(private timeLineService: TimeLineService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(this.globalState$.subscribe((state: GlobalStateModel) => {
      this.globalObject = state.globalObject;
      this.isNft = this.globalObject.isNft;
      this.widgetId = this.globalObject.widget;
      if (this.widgetId) {
        if (!this.noPayment && this.widgetId.hasInsuranceOption === true) {
          this.stepInsuranceExist = true
        }
      }
    }));
    this.subscription.add(this.timeLineService.currentStep$.subscribe(_currentStep => {
      this.currentStep = _currentStep;
      if (_currentStep) {
        if (_currentStep === 'contactInformation' && !this.noCountdown) {
          this.timeLineService.initCountDown();
        }
        else if (_currentStep === 'dateSelection') {
          this.stepDateExist = true;
        }
        else if (_currentStep === 'confirmation') {
          this.timeLineService.stopCountDown();
        }
      }
    }));

    this.subscription.add(this.timeLineService.noPayment$.subscribe(value => {
      this.noPayment = value
      if (this.widgetId) {
        if (!this.noPayment && this.widgetId.hasInsuranceOption === true) {
          this.stepInsuranceExist = true
        }
        else {
          this.stepInsuranceExist = false
        }
      }
    }));
    this.subscription.add(this.timeLineService.noCountdown$.subscribe(value => this.noCountdown = value));
  }


}
