import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { PromoCodeService } from 'src/app/providers';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { GlobalState } from 'src/app/store/states/global.state';
import { RemovePromoCode, SetCart, SetPromoCode } from 'src/app/store/actions/global.actions';
import { Cart } from 'src/app/models/type.definition';
import Event from 'src/app/models/event.model';

@Component({
  selector: 'app-m-coupon-add',
  templateUrl: './m-coupon-add.component.html',
  styleUrls: ['./m-coupon-add.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ maxHeight: '0px' }),
        animate('500ms ease-in', style({ maxHeight: '50px' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ maxHeight: '0px' }))
      ])
    ])
  ]
})
export class MCouponAddComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  showCouponForm: boolean = false;
  coupon: string;
  messageText = '';
  messageType = '';
  eventId: Event;
  inputDisabled: boolean;

  constructor(private promoCodeService: PromoCodeService,
    private store: Store) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.eventId = this.store.selectSnapshot(state => GlobalState.get(state, 'eventId'));
    console.log(this.eventId, "<======");

  }

  removeCoupon(): void {
    this.coupon = ''
    this.messageText = ''
    this.inputDisabled = false
    this.store.dispatch(new RemovePromoCode())
  }

  addCoupon(): void {
    if (this.coupon) {
    this.promoCodeService.checkPromoCode(this.eventId._id, this.coupon).subscribe(
      (res: any) => {
        if (res) {
          this.store.dispatch(new SetPromoCode({ code: res}));
          this.messageType = 'success';
          this.messageText = 'Votre code promo à bien été ajouté';
          this.inputDisabled = true;
        }
        else {
          this.messageType = 'error';
          this.messageText = 'Ce code promo n\'existe pas';
        }
      },
      err => console.log(err))
    }
  }

}
