import checkField from './check-field';
import { Dates, Place, Ticketing } from './type.definition';
import Organisation from './organisation.model';

export default class Event {
    _id: string;
    dates: Dates;
    description: string;
    organisationId: Organisation;
    place: Place[];
    ticketing: Ticketing;
    status: 'draft' | 'published' | 'passed' | 'closed';
    bookType: 'registration' | 'ticketing' | "nft";
    commission: {
        flat: number;
        percentage: number
    };
    commissionIncreased: boolean;
    useRoomPlacement: boolean;
    seatsIoId: string;

    constructor(_event?: any) {
        if (checkField(_event?._id)) {
            this._id = _event._id;
        }
        if (checkField(_event?.dates)) {
            this.dates = _event.dates;
        }
        if (checkField(_event?.description)) {
            this.description = _event.description;
        }
        if (checkField(_event?.place)) {
            this.place = _event.place;
        }
        if (checkField(_event?.ticketing)) {
            this.ticketing = _event.ticketing;
        }
        if (checkField(_event?.organisationId)) {
            this.organisationId = _event.organisationId;
        }
        if (checkField(_event?.commission)) {
            this.commission = _event.commission;
        }
        if (checkField(_event?.commissionIncreased)) {
            this.commissionIncreased = _event.commissionIncreased;
        }
        if (checkField(_event?.status)) {
            this.status = _event.status;
        }
        if (checkField(_event?.bookType)) {
            this.bookType = _event.bookType;
        }
        if (checkField(_event?.useRoomPlacement)) {
            this.useRoomPlacement = _event.useRoomPlacement;
        }
        if (checkField(_event?.seatsIoId)) {
            this.seatsIoId = _event.seatsIoId;
        }
    }
}
