<app-t-content-w-sidebar>

  <div id="insurance-choice" slotContent>
    <app-a-title-main [text]='"Assurance" | customTranslation'></app-a-title-main>
    <app-m-content-section type="white-content-box" *ngIf="insurancePrice">
      <div slotContent>
        <h2 class="title-price">Assurez vos billets pour <span>{{ insurancePrice.formatted }}</span></h2>
        <div class="insurance-line">
          <div class="picto">
            <i class="webfont webfont-check"></i>
          </div>
          <div class="content-line"><span>Garantie de remboursement:</span> Soyez assuré d'être remboursé, avec ou sans justification, même à la dernière minute</div>
        </div>
        <div class="insurance-line">
          <div class="picto">
            <i class="webfont webfont-check"></i>
          </div>
          <div class="content-line"><span>Annulation rapide et efficace:</span> Annulez vos billets à tout moment et recevez votre remboursement sous 48H.</div>
        </div>
        <div class="insurance-line">
          <div class="picto">
            <i class="webfont webfont-check"></i>
          </div>
          <div class="content-line"><span>Assurance en cas d'imprévus:</span>Obtenez un remboursement intégral sur présentation d'un justificatif, ou 70% sans justification.</div>
        </div>
        <div class="seemore">
          <a target="blank" href="https://www.oandb.fr">En savoir plus sur l'assurance annulation</a>
        </div>
        <div class="selection">
          <app-m-form-radio-list [options]="insuranceOptions" [selected]="null"
            (updateSelection)="changeInsuranceOption($event)"
            [required]="true">
          </app-m-form-radio-list>
        </div>
      </div>
    </app-m-content-section>
  </div>

  <!-- SLOT SIDEBAR -->
  <app-o-summary [buttonIsDisabled]="btnDisabled" slotSidebar></app-o-summary>

</app-t-content-w-sidebar>