import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';

import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';

import { RequestResponseList, SetCartModel, Cart } from 'src/app/models/type.definition';
import { SetCart, SetKeysGlobal } from 'src/app/store/actions/global.actions';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

// Providers
import {
  PopInService,
  CurrencyService,
  TicketTypeCategoryService,
  TicketTypeService,
  TimeLineService
} from 'src/app/providers';

import TicketTypeCategory from 'src/app/models/ticket-type-category.model';
import TicketType from 'src/app/models/ticket-type.model';
import AddOn from 'src/app/models/add-on.model';
import Event from 'src/app/models/event.model';
import Ticket from 'src/app/models/ticket.model';
import Session from 'src/app/models/session.model';
import Currency from 'src/app/models/currency.model';
import { map } from 'rxjs/operators';
import Widget from 'src/app/models/widget.model';

declare var seatsio: any;

@Component({
  selector: 'app-seat-selection',
  templateUrl: './seat-selection.html',
  styleUrls: ['./seat-selection.scss']
})
export class SeatSelectionPage implements OnInit, OnDestroy {

  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;

  categories: TicketTypeCategory[];
  noCategory: TicketType[] = [];
  subscriptions = new Subscription();
  stockChange$ = new BehaviorSubject<any>(null);
  ticketsAvailable: boolean;
  event: Event;
  currency: Currency
  session: Session;
  loaded: boolean;
  remainDays: number;
  public isRegistration: boolean;
  public allFree: boolean;
  public widget: Widget;

  // widgetId: string;
  chart: any;

  constructor(
    private timeLineService: TimeLineService,
    private ticketTypeCategoryService: TicketTypeCategoryService,
    private ticketTypeService: TicketTypeService,
    private store: Store,
    private currencyService: CurrencyService,
    private router: Router,
    public route: ActivatedRoute,
    private popInService: PopInService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    // this.timeLineService.updateTimeLine('ticketsList');
    // this.route.params.subscribe(_params => {
    //   this.widgetId = _params.id;

    //   // Todo load data
    //   this.createChart();
    // });

    this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    });
    this.timeLineService.updateTimeLine('ticketsList');
		this.widget = this.store.selectSnapshot(state => GlobalState.get(state, 'widget'));
    this.remainDays = this.calcRemainDays(this.widget.eventId);
    this.session = this.store.selectSnapshot(state => GlobalState.get(state, 'session'));
    this.store.selectSnapshot(state => GlobalState.get(state, 'session'));
    const eventId = this.widget.eventId;
    this.event = this.widget.eventId;
    const categoriesResult: RequestResponseList = await this.getTicketTypeCategories(eventId._id).toPromise();
    this.categories = categoriesResult?.data || [];

    const ticketsResult: TicketType[] = await this.getTicketType(eventId._id, this.widget).toPromise();
    if (ticketsResult?.length) {
      this.formatTickets(ticketsResult);
      // Load chart
      this.createChart(this.categories);
    }
    else {
      this.store.dispatch(new SetKeysGlobal({ value: null, key: 'widget' }));
      this.timeLineService.currentStep$.next();
      this.router.navigate([`${this.widget._id}/closed`]);
    }
    this.formatEvents();
    this.formatCategories();
    const cart = this.store.selectSnapshot(state => GlobalState.get(state, 'cart'));
    this.checkSelectedTickets(cart)
    const availableTickets = this.sortAndGetAvailableTickets();
    this.isRegistration = this.store.selectSnapshot(state => GlobalState.get(state, 'isRegistration'));
    this.allFree = this.store.selectSnapshot(state => GlobalState.get(state, 'allFree'));
    this.store.dispatch(new SetKeysGlobal({ value: availableTickets, key: 'tickets' }));
    this.ticketsAvailable = this.checkIfAvailable();
    if (!this.ticketsAvailable) {
      this.timeLineService.deletePayment();
      this.timeLineService.deleteCountdown();
    }
    this.loaded = true;
  }

  ticketsAvailableList = [];
  ticketsSelectedList = [];

  orderError: string;

  deleteOrderError() {
    this.orderError = null;
  }

  createChart(_categories) {

    let pricing = [];

    for (let i = 0; i < _categories.length; ++i) {

      let ticketType = [];

      if (_categories[i].tickets) {
        for (let j = 0; j < _categories[i].tickets.length; ++j) {

          ticketType.push({
            ticketType: _categories[i].tickets[j]._id,
            price: _categories[i].tickets[j].price,
            label: _categories[i].tickets[j].name
          });

          this.ticketsAvailableList.push(_categories[i].tickets[j]);

          if (_categories[i].startPrice == 0) {
            this.categories[i].startPrice = _categories[i].tickets[j].price;
          } else if (_categories[i].tickets[j].price < _categories[i].startPrice) {
            this.categories[i].startPrice = _categories[i].tickets[j].price;
          }

        }
      }


      pricing.push({
        category: _categories[i].seatsIoCategoryId,
        ticketTypes: ticketType
      });
    }

    let that = this;

    let seatsIoEventId = this.widget.eventId._id;

    if (this.session) {
      seatsIoEventId = this.session._id;
    }

    this.chart = new seatsio.SeatingChart({
      divId: "seatsio",
      region: "eu",
      language: "fr",
      workspaceKey: this.widget.organisationId.seatsIoWorkspace,
      event: seatsIoEventId,
      pricing: pricing,
      priceFormatter: price => (price + ' €'),
      priceLevelsTooltipMessage: "Sélectionnez le ticket souhaité",
      onObjectSelected: function (object) {
        const existingSeat = that.ticketsSelectedList.find(_ => _.seat === object.seatId);
        if (existingSeat) {
          that.increment(existingSeat);
        }
        else {
          for (let i = 0; i < that.ticketsAvailableList.length; ++i) {
            if (that.ticketsAvailableList[i]._id === object.selectedTicketType) {
              const newSeat = {
                addons: that.ticketsAvailableList[i].addons,
                categoryName: that.ticketsAvailableList[i].categoryName,
                _id: that.ticketsAvailableList[i]._id,
                isVisible: that.ticketsAvailableList[i].isVisible,
                name: that.ticketsAvailableList[i].name,
                price: that.ticketsAvailableList[i].price,
                quantity: that.ticketsAvailableList[i].quantity,
                quota: that.ticketsAvailableList[i].quota,
                seat: object.seatId,
                ticketTypeCategoryId: that.ticketsAvailableList[i].ticketTypeCategoryId,
                tickets: that.ticketsAvailableList[i].tickets
              }
              that.ticketsSelectedList.push(newSeat);
              that.increment(newSeat);
              break;
            }
          }
        }
      },
      onObjectDeselected: function (object) {

        if (object.status === "reservedByToken") {
          that.popInService.displayPopIn({
            firstClick: that.deleteOrderError,
            close: that.deleteOrderError,
            infoText: `La place ${object.id} vient d'être prise par une autre personne, veuillez changer votre place.`,
            firstButtonText: 'Ok'
          });
        }
        // remove the deselected seat id from the array
        for (let i = 0; i < that.ticketsSelectedList.length; ++i) {
          if (that.ticketsSelectedList[i].seat === object.id) {
            that.decrement(that.ticketsSelectedList[i]);
            break;
          }
        }
      }
    }).render();
  }

  rateQuantity: number;
  rateMinSelectable: number;
  quantity: number;

  increment(_ticket): void {
    _ticket.quantity = _ticket.quantity + 1;
    this.updateRateQuantity(_ticket, _ticket.quantity, -1);
  }

  decrement(_ticket): void {
    _ticket.quantity = _ticket.quantity - 1;
    this.updateRateQuantity(_ticket, _ticket.quantity, 1);
  }

  updateRateQuantity(_ticket, newQuantity, sum): void {
    if (this.rateMinSelectable && !this.rateQuantity) {
      for (let i = 1; i <= this.rateMinSelectable; i++) {
        this.changeStockAndEmit(_ticket, sum, i);
      }
    }
    else if (this.rateMinSelectable && this.rateMinSelectable === newQuantity + 1) {
      for (let i = this.rateQuantity - 1; i >= 0; i--) {
        this.changeStockAndEmit(_ticket, sum, i);
      }
    }
    else {
      this.changeStockAndEmit(_ticket, sum, newQuantity);
    }
  }

  changeStockAndEmit(_ticket, sum, quantity) {
    this.rateQuantity += -sum;
    this.updateQuantity(_ticket, { quantity, sum });
  }

  getTicketTypeCategories(_eventId: string): Observable<RequestResponseList> {
    // Body de la requête getList du TicketTypeCategoryService
    const body = {
      filter: { eventId: _eventId, isProduct: this.widget.forProduct }
    };
    // Requête pour récupérer les catégories de types tickets liés à l'évènement
    return this.ticketTypeCategoryService.getList(body);
  }

  getTicketType(_eventId: string, widget: Widget): Observable<TicketType[]> {
    // Body de la requête getList du TicketTypeService
    const body = {
      perPage: 500,
      filter: {
        eventId: _eventId,
        sellingDate: true,
        isProduct: this.widget.forProduct
      },
    };

    // Requête pour récupérer les types de tickets
    return this.ticketTypeService.getList(body)
      .pipe(
        map((tickets: { data: TicketType[] }) => {
          const widgetTickets = widget.ticketTypes;
          return (tickets?.data || []).filter(ticket => {
            if (widgetTickets.restrict && !widgetTickets.ids.includes(ticket._id)) {
              return false
            }
            else if (this.session && this.session.isTicketTypeRestricted && !this.session.ticketTypeIds.map(_ => _._id).includes(ticket._id)) {
							return false
						}
            else {
              return true
            }
          })
      }))
  }

  formatTickets(_tickets: TicketType[]): void {
    for (let i = 0; i < _tickets.length; i++) {
      if (!_tickets[i].ticketTypeCategoryId) {
        if (_tickets[i].isVisible) {
          this.noCategory.push(_tickets[i]);
        }
        _tickets.splice(i--, 1)[0];
      }
      else {
        for (const category of this.categories) {
          if (category._id === _tickets[i].ticketTypeCategoryId._id) {
            if (_tickets[i].isVisible) {
              if (typeof category.tickets === 'undefined') {
                category.tickets = [];
              }
              _tickets[i].categoryName = category.name;
              category.tickets.push(_tickets[i]);
            }
            _tickets.splice(i--, 1)[0];
            break;
          }
        }
      }
    }
  }

  formatCategories(): void {
    this.categories = this.categories.filter(category => category.tickets?.length);
    this.categories.forEach(category => {
      if (category.quota && category.stock === 0) {
        category.tickets.forEach(ticket => {
          ticket.unavailable = true;
        })
      }
    });
  }

  formatEvents(): void {
    if (this.event.ticketing.quota && this.event.ticketing.stock == 0) {
      this.categories.forEach(category => {
        category.stock = 0;
      })
      this.noCategory.forEach(ticket => {
        ticket.unavailable = true;
      })
    }
  }

  checkIfAvailable() {
    if (!this.event.ticketing.quota || this.event.ticketing?.stock) {
      if (!this.session || (!this.session.quota || this.session.stock)) {
        for (const category of this.categories) {
          if (category.stock) {
            if (category.tickets) {
              for (const ticket of category.tickets) {
                if (!ticket.quota|| ticket.stock) {
                  return true;
                }
              }
            }
          }
        }
        for (const ticket of this.noCategory) {
          if (!ticket.quota || ticket.stock) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getCategoryLowestPrice(category: TicketTypeCategory): number {
    if (category.tickets) {
      return Math.min(...category.tickets.map(ticket => ticket.price));
    }
  }

  // Met à jour la quantité de tickets sélectionnés
  updateQuantity(_ticket: TicketType, event: any): void {
    _ticket.quantity = event.quantity;
    const updateOption: SetCartModel = {
      quantity: _ticket.quantity,
      ticket: _ticket,
    };
    this.event.ticketing.stock += event.sum;
    if (this.session) {
      this.session.stock += event.sum;
    }
    let category: TicketTypeCategory;
    if (_ticket.ticketTypeCategoryId) {
      category = this.categories.find(_ => _._id === _ticket.ticketTypeCategoryId._id);
      category.stock += event.sum
    }
    this.stockChange$.next({
      categoryId: category?._id,
      categoryStock: category?.stock,
      eventStock: this.event.ticketing.stock,
      sessionStock: this.session?.stock
    });
    this.store.dispatch(new SetCart(updateOption));
  }

  checkSelectedTickets(_cart?: Cart) {
    _cart?.tickets?.forEach(_ticket => {
      if (_ticket.ticketTypeCategoryId) {
        const categories = this.categories.filter(_ => _ticket.ticketTypeCategoryId._id === _._id)[0];
        categories.tickets = categories.tickets.map(_ => _._id === _ticket._id ? _ticket : _);
      }
      else {
        this.noCategory = this.noCategory.map(_ => {
          return _ticket._id === _._id ? _ticket : _
        })
      }
    })
  }

  isCategoryExpand(_category: TicketTypeCategory): number {
    return _category?.tickets?.map(_ => _.quantity).reduce((a: any, c: any) => a + c)
  }

  sortAndGetAvailableTickets(): TicketType[] {
    let tickets: TicketType[] = [];
    this.categories?.forEach(_cat => {
      tickets = this.getAvailableTickets(tickets, _cat.tickets, _cat.name)
    })
    tickets = this.getAvailableTickets(tickets, this.noCategory)

    return tickets
  }

  getAvailableTickets(_ticketsArray: TicketType[], _tickets?: TicketType[], _categoryName: string = null): TicketType[] {
    _tickets?.forEach(_ => _ticketsArray.push(new TicketType({ ..._, categoryName: _categoryName })));
    return _ticketsArray
  }

  calcRemainDays(event: Event): number {

    const endDate = event.ticketing.date.endDate
    if (endDate) {
      return this.getDaysBetweenDates(new Date(endDate), new Date())
    }
    else {
      return this.getDaysBetweenDates(new Date(event.dates.startDate), new Date());
    }
  }
  getDaysBetweenDates(date1: Date, date2: Date): number {
    return Math.floor((date1.getTime() - date2.getTime()) / 86400000);
  }
}