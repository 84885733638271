<app-t-content-center>
  <app-o-confirmation *ngIf="registration != undefined && type === 'ticketing'" slotContent>
    <h1 *ngIf="!registration" slotTitle>{{"Merci pour votre achat !" | customTranslation: "thxPurchase"}} </h1>
    <h1 *ngIf="registration" slotTitle>{{"Votre inscription a bien été enregistrée !" | customTranslation: "thxRegistration"}} </h1>
    <p slotText>{{"Vous allez recevoir le(s) e-billet(s) sur l’adresse e-mail renseignée" | customTranslation:
      "ticketByMails"}}</p>
    <p *ngIf="tickets?.length == 0" slotText>{{"Ou vous pouvez le(s) télécharger en" | customTranslation: "orDownload"}}
      <a (click)="getOrderData()" style="cursor: pointer;">{{"cliquant ici" | customTranslation: "clickHere"}}</a>
    </p>
    <p *ngIf="!streamPossible && getOrderExecuted" slotText>{{"Les documents ne sont pas disponibles au téléchargement"
      +
      " actuellement, ils vous seront envoyés par email lorsqu'ils auront été généré." | customTranslation: "infoDocs"
      }}</p>
    <ul *ngIf="tickets?.length > 0 && streamPossible" slotText>
      <li *ngIf="event.bookType != 'registration'" style="margin-bottom: 8px;">
        <p><a (click)="stream('orderId', invoice)" style="cursor: pointer;">{{"Télécharger la facture" |
            customTranslation: "dwnldBill"}}</a></p>
      </li>
      <li *ngFor="let ticket of tickets" style="margin-bottom: 8px;">
        <p><a (click)="stream('ticketId', ticket)" style="cursor: pointer;">{{"Télécharger le ticket n°"
            | customTranslation:"dwnldTicket"}}{{ticket.ticketNumber }}</a></p>
      </li>
    </ul>
    <app-a-button *ngIf="!registration" slotActions [text]="'Acheter d\'autre tickets !' | customTranslation: 'buyMore'"
      (buttonClicked)="buyMoreTickets()"></app-a-button>
      <app-a-button *ngIf="registration" slotActions [text]="'Ajouter des inscriptions' | customTranslation: 'registerMore'"
      (buttonClicked)="buyMoreTickets()"></app-a-button>
  </app-o-confirmation>
  <app-o-confirmation *ngIf="type === 'registration'" slotContent>
    <h1 slotTitle>{{"Merci pour votre inscription !" | customTranslation: "thxRegister"}} </h1>
    <p slotText>{{"Nous vous tiendrons informé dès que les places seront disponibles." | customTranslation:
      "moreWillCome"}}</p>
    <app-a-button slotActions [text]="'Terminer' | customTranslation: 'finish'" (buttonClicked)="over()"></app-a-button>
  </app-o-confirmation>
  <app-o-confirmation *ngIf="type === 'nft'" slotContent>
    <h1 slotTitle>{{"Merci pour votre achat !" | customTranslation: "thxPurchase"}} </h1>
    <p slotText>Vous allez recevoir la procédure de récupération de vos billets sur l’adresse e-mail renseignée.</p>
    <p slotText>Les billets que vous venez d'acheter sont des billets NFT sécurisés. Veuillez vous rendre sur <a href="https://wallet.oandb.fr" target="_blank">OandB Wallet</a> et créer un compte avec l'adresse e-mail renseignée. Vos tickets seront automatiquement rattachés à votre compte. Si vous avez déjà un compte OandB Wallet, vos tickets seront disponibles d'ici quelques minutes. En fonction de l'affluence les tickets peuvent mettre plusieurs heures à s'afficher dans votre espace OandB Wallet.</p>
    <p slotText>Note : Si vous avez renseigné plusieurs e-mails différents, chaque personne devra créer son compte OandB Wallet pour récupérer leur(s) propre(s) billet(s). Chacun recevra un e-mail avec la procédure à suivre.</p>
    <p slotText>Vous allez également recevoir un email avec la procédure complète pour récupérer vos billets.</p>
    <p *ngIf="!invoice" slotText>Vous pouvez obtenir la facture en
      <a (click)="getOrderData()" style="cursor: pointer;">{{"cliquant ici" | customTranslation: "clickHere"}}</a>
    </p>
    <p *ngIf="!streamPossible && getOrderExecuted" slotText>{{"Les documents ne sont pas disponibles au téléchargement"
      +
      " actuellement, ils vous seront envoyés par email lorsqu'ils auront été généré." | customTranslation: "infoDocs"
      }}</p>
    <ul *ngIf="invoice && streamPossible" slotText>
      <li *ngIf="event.bookType != 'registration'" style="margin-bottom: 8px;">
        <p><a (click)="stream('orderId', invoice)" style="cursor: pointer;">{{"Télécharger la facture" |
            customTranslation: "dwnldBill"}}</a></p>
      </li>
    </ul>
    <app-a-button *ngIf="!registration" slotActions [text]="'Acheter d\'autre tickets !' | customTranslation: 'buyMore'"
      (buttonClicked)="buyMoreTickets()"></app-a-button>
      <app-a-button *ngIf="registration" slotActions [text]="'Ajouter des inscriptions' | customTranslation: 'registerMore'"
      (buttonClicked)="buyMoreTickets()"></app-a-button>
  </app-o-confirmation>
</app-t-content-center>