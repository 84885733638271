import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Pages
import { OrderSummaryPage } from './pages/order-summary/order-summary';
import { InitPage } from './pages/init-page/init-page';
import { InsurancePage } from './pages/insurance/insurance';
import { ClosedPage } from './pages/closed/closed';
import { DateSelectionPage } from './pages/date-selection/date-selection';
import { TicketsListPage } from './pages/tickets-list/tickets-list';
import { SeatSelectionPage } from './pages/seat-selection/seat-selection';
import { ContactInformationPage } from './pages/contact-information/contact-information';
import { SummaryPage } from './pages/summary/summary';
import { ConfirmationPage } from './pages/confirmation/confirmation';
import { RegistrationPage } from './pages/registration/registration';
import { NotFoundPage } from './pages/not-found/not-found';
import { NotAuthorizedPage } from './pages/not-authorized/not-authorized';
import { NotFromBarGuard } from './etc/guard/not-from-bar.guard';

const routes: Routes = [
  // Pages
  { path: '', component: NotFoundPage },
  { path: '404', component: NotFoundPage },
  { path: '403', component: NotAuthorizedPage },
  { path: 'en', redirectTo: '' },
  { path: ':id', component: InitPage },
  { path: ':id/closed', component: ClosedPage, canActivate: [NotFromBarGuard] },
  { path: ':id/date-selection', component: DateSelectionPage },
  { path: ':id/tickets-list', component: TicketsListPage },
  { path: ':id/seat-selection', component: SeatSelectionPage },
  { path: ':id/insurance', component: InsurancePage },
  { path: ':id/order-summary', component: OrderSummaryPage },
  { path: ':id/contact-information', component: ContactInformationPage },
  { path: ':id/summary', component: SummaryPage },
  { path: ':id/confirmation/:type/:token', component: ConfirmationPage },
  { path: ':id/registration', component: RegistrationPage }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
