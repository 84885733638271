export type TranslatedText = {
    original?: string,
    en: string,
    es: string
}

export type TranslatedTexts = {
    [key: string]: TranslatedText
}

export const Translations: TranslatedTexts = {
    /** Header */
    ticketing: {
        en: "TICKETING",
        es: "BILLETAJE"
    },
    registration: {
        en: "registration",
        es: "registro"
    },
    startingAt: {
        en: "starting at",
        es: "desde"
    },
    ticketingClosed: {
        es: "La taquilla está cerrada",
        en: "The ticket office is closed"
    },
    registrationClosed: {
        en: "Registrations are closed",
        es: "Las inscripciones están cerradas"
    },
    thisTicketingClosed: {
        es: "Esta taquilla está cerrada",
        en: "This ticket office is closed"
    },
    ticketingCloseIn: {
        en: "The ticket office closes in",
        es: "La venta de entradas se cierra en"
    },
    registrationCloseIn: {
        en: "Registration closes in",
        es: "Las inscripciones se cierran enn"
    },
    "aujourd'hui": {
        en: "today",
        es: "hoy"
    },
    registrationClose: {
        en: "Registration closes",
        es: "Las inscripciones se cierran"
    },
    ticketingClose: {
        en: "The ticket office closes",
        es: "La venta de entradas se cierra"
    },
    day: {
        en: "day",
        es: "día"
    },
    alreadyRegistered: {
        en: "Already registered? Get your tickets by",
        es: "¿Ya está registrado? Consiga sus entradas haciendo "
    },
    alreadyOrdered: {
        en: "Already ordered? Get your tickets by",
        es: "¿Ya ha hecho el pedido? Consiga sus entradas haciendo "
    },
    "cliquant ici": {
        en: "clicking here",
        es: "clic aquí"
    },

    /** Timeline */
    "Date": {
        es: "Fetcha",
        en: "Date"
    },
    timelineBooking: {
        es: "Reserva",
        en: "Reservation"
    },
    "Coordonnées": {
        en: "Contact details",
        es: "Detalles de contacto"
    },
    timelinePayment: {
        en: "Payment",
        es: "Pago"
    },
    timelineConfirm: {
        en: "Confirmation",
        es: "Confirmación"
    },
    invalidForm: {
        en: "Please fill in the missing fields to proceed with the payment of your order.",
        es: "Por favor, rellene los campos que faltan para proceder al pago de su pedido."
    },

    /** Session */
    "Lun": {
        es: "Lun",
        en: "Mon"
    },
    "Mar": {
        es: "Mar",
        en: "Tue"
    },
    "Mer": {
        es: "Mié",
        en: "Wed"
    },
    "Jeu": {
        es: "Jue",
        en: "Thu"
    },
    "Ven": {
        es: "Vie",
        en: "Fri"
    },
    "Sam": {
        es: "Sáb",
        en: "Sat"
    },
    "Dim": {
        es: "Dom",
        en: "Sun"
    },
    chooseDate: {
        es: "Elija una fecha",
        en: "Choose a date"
    },
    pleaseChooseDate: {
        es: "Seleccione una fecha del calendario",
        en: "Please select a date from the calendar"
    },
    'Heure': {
        en: 'Hour',
        es: 'Hora'
    },
    'Suivant': {
        es: 'Siguiente',
        en: 'Next'
    },

    /** Tickets */
    categoryChoice: {
        en: "Choice by category",
        es: "Selección por categoría"
    },
    otherChoice: {
        es: "Otra opción",
        en: "Other choice"
    },
    unavailable: {
        en: "unavailable",
        es: "indisponible"
    },
    "ticket": {
        en: "ticket",
        es: "registro"
    },
    "produit": {
        en: "product",
        es: "producto"
    },
    "inscription": {
        en: "ticket",
        es: "billete"
    },
    noTicketsAvailable: {
        es: "Todas las entradas se agotaron.",
        en: "All seats have been sold."
    },
    registerWaitingList: {
        es: "Inscribirse en la lista de espera",
        en: "Register on the waiting list"
    },

    /** Registration */
    descRegistration: {
        es: "Por favor, rellene sus datos de contacto para que nuestro equipo pueda ponerse en contacto con usted si hay nuevas entradas disponibles:",
        en: "Please fill in your contact information so that our team can contact you if new tickets become available:"
    },
    "Ticket souhaité": {
        es: "Billete deseado",
        en: "Desired ticket"
    },
    "Enregistrer": {
        es: "Registro",
        en: "Register"
    },


    /** Summary */
    summary: {
        en: "Summary",
        es: "Resumen"
    },
    free: {
        en: "Free",
        es: "Gratis"
    },
    purchase: {
        es: "Comprar",
        en: "Purchase"
    },
    register: {
        es: "Registrar",
        en: "Register"
    },
    infoPurchase: {
        es: "Proceder al pago",
        en: "Proceed to payment"
    },
    finalPay: {
        es: "Pagar",
        en: "Pay"
    },
    addPromoCode: {
        en: "Add a coupon code",
        es: "Añadir un código promocional"
    },
    removePromoCode: {
        en: "Remove the coupon code",
        es: "Eliminar código promocional"
    },
    promoCode: {
        en: "Coupon Code",
        es: "Código Promocional"
    },
    service: {
        en: 'Service charge',
        es: 'Gastos de servicio'
    },
    infoPriceDisplayed: {
        es: "Los precios indicados incluyen todos los impuestos",
        en: "The prices shown are inclusive of all taxes"
    },


    /*** Contact / Infos */
    radioSameMail: {
        es: "Me gustaría recibir todas las entradas en la misma dirección de correo electrónico.",
        en: "I would like to receive all tickets on the same e-mail address."
    },
    radioAllMails: {
        es: "Me gustaría que cada participante recibiera un billete electrónico.",
        en: "I would like each participant to receive an e-ticket."
    },
    ticketList: {
        es: 'Lista de productos',
        en: 'List of products'
    },
    productList: {
        es: 'Lista de entradas',
        en: 'List of tickets'
    },
    /** Preselect FormField */
    "Civilité": {
        es: "Civilidad",
        en: "Civility"
    },
    "Date de naissance": {
        en: "Date of birth",
        es: "Fecha de nacimiento"
    },
    "Prénom": {
        en: "First name",
        es: "Nombre"
    },
    "Nom": {
        es: "Apellido",
        en: "Last name"
    },
    "Adresse e-mail": {
        es: "Correo electrónico",
        en: "E-mail"
    },
    "Emplacement (Zone, Place, Loge)": {
        es: "Ubicación (Zona, Lugar, Albergue)",
        en: "Location (Zone, Place, Lodge)"
    },
    "E-mail": {
        es: "Correo electrónico",
        en: "E-mail"
    },
    "Code Postal": {
        en: "Zip code",
        es: "Código postal"
    },
    "Téléphone": {
        es: "Teléfono",
        en: "Phone"
    },
    "Site internet": {
        en: "Website",
        es: "Página web"
    },
    "Âge": {
        en: "Age",
        es: "Edad"
    },
    "Adresse": {
        en: "Address",
        es: "Dirección"
    },
    "Ville": {
        es: "Ciudad",
        en: "City"
    },
    "Pays": {
        es: "País",
        en: "Country"
    },

    /** Billing Form */
    infos: {
        es: 'Información',
        en: 'Information'
    },
    billingInfos: {
        es: 'Información de facturación',
        en: 'Billing Information'
    },
    ms: {
        es: "Señora",
        en: "Madame"
    },
    mr: {
        es: "Señor",
        en: "Mister"
    },
    defaultSendInvoice: {
        en: "I would like to receive an invoice by email",
        es: "Me gustaría recibir una factura por correo electrónico"
    },
    defaultTerm: {
        en: "I agree that the contact information provided may be used to issue the ticket(s) and invoice and to contact me if necessary.",
        es: "Estoy de acuerdo en que los datos de contacto facilitados se utilicen para emitir la(s) entrada(s) y la factura y para ponerse en contacto conmigo en caso necesario."
    },
    readMore: {
        es: "Leer más",
        en: "Read more"
    },

    /** Form - General */
    invalidInput: {
        en: "Please enter a valid data",
        es: "Por favor, introduzca un dato válido"
    },
    incompleteForms: {
        es: "Por favor, rellene los campos que faltan",
        en: "Please fill in the missing fields"
    },
    options: {
        es: "Opciones",
        en: "Options"
    },
    thisAddon: {
        es: "esta opción",
        en: "this option"
    },

    /** Paiement */

    /** Columns Name */
    finalTitle: {
        es: "Rúbrica",
        en: "Name"
    },
    finalPrice: {
        es: "Precio",
        en: "Price"
    },
    finalQty: {
        es: "Qty",
        en: "Cantidad"
    },
    'Total': {
        es: "Total",
        en: "Total"
    },
    /** Details */
    finalPromoCode: {
        en: "Promo code",
        es: "Código promocional"
    },
    onNormalRate: {
        es: "sobre el tipo normal",
        en: "on the normal rate"
    },
    vatIncluded: {
        en: "VAT INCLUDED",
        es: "IVA INCLUIDO"
    },

    /** Payment Box */
    bankDetails: {
        es: "Datos bancarios",
        en: "Bank details"
    },
    paymentError: {
        en: "The payment could not be made. Your bank refused the payment.",
        es: "El pago no pudo realizarse. Su banco rechazó el pago."
    },
    cardOwner: {
        es: "Titular de la tarjeta",
        en: "Cardholder"
    },
    infoInvalids: {
        en: "Invalid information",
        es: "Información no válida"
    },
    securePayment: {
        es: "Pago seguro",
        en: "Secure payment"
    },

    /** Confirmation */
    thxPurchase: {
        es: "¡Gracias por su compra!",
        en: "Thank you for your purchase!"
    },
    thxRegistration: {
        es: "¡Su registro ha sido exitoso!",
        en: "Your registration has been successful!"
    },
    ticketByMails: {
        es: "Recibirá los billetes electrónicos en la dirección de correo electrónico que haya indicado",
        en: "You will receive the e-ticket(s) on the e-mail address you provided"
    },
    orDownload: {
        es: "O puede descargarlo haciendo",
        en: "Or you can download it by"
    },
    clickHere: {
        es: "clic aquí",
        en: "clicking here"
    },
    infoDocs: {
        es: "Los documentos no están actualmente disponibles para su descarga, se le enviarán por correo electrónico cuando se hayan generado.",
        en: "The documents are not currently available for download, they will be sent to you by email when they have been generated."
    },
    dwnldBill: {
        es: "Descargar la factura",
        en: "Download the invoice"
    },
    dwnldTicket: {
        es: "Descargar el billete n°",
        en: "Download the ticket n°"
    },
    buyMore: {
        es: "¡Compre más billetes!",
        en: "Buy more tickets!"
    },
    registerMore: {
        es: "Añadir más inscripciones",
        en: "Add more registrations"
    },
    thxRegister: {
        es: "Gracias por su inscripción.",
        en: "Thank you for your registration!"
    },
    moreWillCome: {
        es: "Le mantendremos informado en cuanto haya plazas disponibles.",
        en: "We will keep you informed as soon as places become available."
    },
    finish: {
        es: "Acabado",
        en: "Finish"
    },

    /** Errors / Infos */
    eventNoMoreTicket: {
        es: "Este evento no tiene más espacio disponible",
        en: "This event has no more space available"
    },
    eventLimitTicket: {
        en: 'Only {stock} ticket(s) left for this event',
        es: 'Sólo quedan {stock} entrada(s) para este evento'
    },
    addonLimitTicket: {
        en: 'Only {stock} addon(s) left for this event',
        es: 'Sólo quedan {stock} addon(s) para este evento'
    },
    addonNoMoreTicket: {
        en: 'The addon {name} is no more available',
        es: 'El addon {name} no tiene más espacio disponible'
    },
    sessionNoMoreTicket: {
        es: "Esta sesión no tiene más espacio disponible",
        en: "This session has no more space available"
    },
    sessionLimitTicket: {
        en: 'Only {stock} ticket(s) left for this session',
        es: 'Sólo quedan {stock} entrada(s) para esta sesión'
    },
    categoryNotOpened: {
        es: 'La categoría {name} no está abierta a la compra',
        en: 'The category {name} is not open for purchase'
    },
    categoryNoMoreTicket: {
        en: 'The category {name} has no more space available',
        es: 'La categoría {name} no tiene más espacio disponible'
    },
    categoryLimitTicket: {
        en: 'Only {stock} ticket(s) left for the category {name}',
        es: 'Sólo quedan {stock} entrada(s) para la categoría {name}'
    },

    minSelect: {
        es: 'Debe seleccionar un mínimo de {value} billete(s) del tipo {name}',
        en: 'You must select a minimum of {value} ticket(s) of type {name}'
    },
    maxSelect: {
        es: 'No puede seleccionar más de {value} billete(s) del tipo {name}',
        en: 'You cannot select more than {value} ticket(s) of type {name}'
    },
    timeLimit: {
        es: 'Se ha superado el límite de tiempo para la compra de el tipo de billete {name}',
        en: 'The time limit has been exceeded for the purchase of the type of ticket {name}'
    },
    ticketNoMoreTicket: {
        en: 'There is no more stock available for the type of ticket {name}',
        es: 'No hay existencias disponibles para el tipo de billete {name}'
    },
    ticketLimitTicket: {
        en: 'Only {stock} ticket(s) of type {name}',
        es: 'Sólo quedan {stock} billete(s) del tipo {name}'
    },
    ticketDoesntExist: {
        es: 'El tipo de billete {name} no existe',
        en: 'The type of ticket {name} does not exist'
    },
    noMoreStockFor: {
        en: 'No more stock available for ',
        es: 'No hay más existencias disponibles para'
    },
    thisEvent: {
        en: 'this event',
        es: 'este evento'
    },
    thisSession: {
        en: 'this session',
        es: 'esta sesión'
    },
    thisCategory: {
        en: 'this category',
        es: 'esta categoría'
    },
    thisTicket: {
        en: 'this ticket',
        es: 'esta entrada'
    },
    addonLimited: {
        en: 'Only {stock} option(s) {name} in stock',
        es: 'Sólo {stock} opcion(es) {name} en stock'
    },
    noAddonAvailable: {
        en: 'No stock available for the option {name}',
        es: 'No hay existencias disponibles para la opción {name}'
    },


    /** Other */
    resetCart: {
        es: "Esta acción restablecerá su cesta de la compra.",
        en: "This action will reset your cart."
    },
    continue: {
        en: 'Continue?',
        es: '¿Continuar?'
    },
    "Oui": {
        en: "Yes",
        es: "Sí"
    },
    "Non": {
        en: "No",
        es: "No"
    },
    "Options": {
        es: 'Opciones',
        en: "Options"
    },

    /** Resend Order */
    enterResendEmail: {
        en: 'Please enter the email address with which the order was placed',
        es: 'Introduzca la dirección de correo electrónico utilizada para realizar el pedido.'
    },
    "Envoyer": {
        en: 'Send',
        es: 'Enviar'
    },
    sentIfExist: {
        es: "Si esta dirección de correo electrónico existe, recibirá su pedido",
        en: "If this email address exists you will receive your order"
    },
    "Retour": {
        en: "Back",
        es: "Volver"
    },

    // Page Error
    dontExist: {
        es: "La página a la que intenta acceder no existe.",
        en: "The page you are trying to access does not exist."
    },
    pageError: {
        en: "The page may have been deleted or the url has an error.",
        es: "Puede que la página haya sido borrada o que la url tenga un error."
    }



}