// Données persistantes du widget (Widget et énvement en cours + données du panier)

import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GlobalStateObject, SetCartModel, Cart } from '../../models/type.definition';
import { SetKeysGlobal, SetCart, SetAddOn, SetPromoCode, SetBookingTime, RemovePromoCode, SetInsurance } from '../actions/global.actions';
import TicketTypeCategory from 'src/app/models/ticket-type-category.model';
import TicketType from 'src/app/models/ticket-type.model';
import FormField from 'src/app/models/form-field.model';
import TicketForm from 'src/app/models/ticket-form.model';
import PromoCode from 'src/app/models/promo-code.model';

export class GlobalStateModel {
  globalObject: GlobalStateObject;
}

@State<GlobalStateModel>({
  name: 'globalObject',
  defaults: {
    globalObject: {
      insurance: null,
      isNft: false,
      isRegistration: false,
      allFree: false,
      eventId: null,
      sessions: null,
      session: null,
      widgetId: null,
      widget: null,
      order: null,
      currency: null,
      tickets: [],
      billingForm: null,
      generalAddOns: [],
      cart: {
        tickets: [],
        addons: [],
        promoCode: null,
        promoCodeQty: null
      },
      orderTicketForms: null,
    }
  }
})

export class GlobalState {

  constructor() { }

  @Selector()
  static get(state: GlobalStateModel, key?: string): GlobalStateObject | any {
    return key ? state.globalObject['globalObject'][key] : state.globalObject['globalObject'];
  }

  @Action(SetKeysGlobal)
  setKeys({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetKeysGlobal): void {
    const state = getState();
    state.globalObject[payload.key] = payload.value;
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetCart)
  setCart({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetCart): void {

    const state = getState();
    const cart = state.globalObject.cart;

    let type = null;

    // If selected seat
    if (payload.ticket.seat) {
      type = cart.tickets.filter(_ => _._id === payload.ticket._id && _.seat === payload.ticket.seat);
    } else { // If classic method
      type = cart.tickets.filter(_ => _._id === payload.ticket._id);
    }

    // const type = cart.tickets.filter(_ => _._id === payload.ticket._id);

    if(type.length > payload.quantity){
      cart.tickets.splice(cart.tickets.indexOf(type[type.length - 1]), 1);
    }
    else {
      const newTicket = this.copyObject(payload.ticket);
      cart.tickets.push(newTicket)
    }
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetAddOn)
  setAddOn({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetAddOn): void {
    const state = getState();
    const cart = state.globalObject.cart;
    if (payload.addOn) {
      if (payload.addOn.isChecked) {
        cart.addons.push(payload.addOn);
      }
      else {
        cart.addons.splice(cart.addons.indexOf(payload.addOn), 1);
      }
    }
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetInsurance)
  setInsurance({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetInsurance): void {
    const state = getState();
    patchState({
      globalObject: {
        ...state.globalObject,
        insurance: payload.amount
      }
    });
  }

  @Action(SetPromoCode)
  setPromoCode({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetPromoCode): void {
    const state = getState();
    const cart = state.globalObject.cart;
    if (payload.code) {
        cart.promoCode = payload.code;
    }
    cart.promoCodeQty = 0
    if (payload.qty) {
      cart.promoCodeQty = payload.qty;
    }
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(RemovePromoCode)
  removePromoCode({ getState, patchState }: StateContext<GlobalStateModel>): void {
    const state = getState();
    const cart = state.globalObject.cart;
    cart.promoCode = null;
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetBookingTime)
  setBookingTime({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetBookingTime): void {
    const state = getState();
    state.globalObject.widget.eventId.ticketing.bookingTime = payload.value;
    patchState({
      globalObject: state.globalObject
    });
  }

  updateTicket(_cart: Cart, _payload: SetCartModel): void {

  }

  copyObject(_object: any): any {
    const newObject = {};
    for (const key in _object) {
      if (Array.isArray(_object[key])) {
        newObject[key] = [];
        _object[key].forEach(_ => {
          if (_ instanceof Object) {
            if (_ instanceof FormField) {
              newObject[key].push(new FormField(this.copyObject(_)));
            }
            else {
              newObject[key].push(this.copyObject(_));
            }
          }
          else {
            newObject[key].push(_);
          }
        });
      }
      else if (_object[key] instanceof Object && typeof _object[key] !== 'function') {
        if (_object[key] instanceof TicketForm) {
          newObject[key] = new TicketForm(this.copyObject(_object[key]));
        }
        else {
          newObject[key] = this.copyObject(_object[key]);
        }
      }
      else {
        newObject[key] = _object[key];
      }
    }
    return newObject
  }

}
