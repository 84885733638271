import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/providers';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.html',
    styleUrls: ['./maintenance.scss']
  })
  export class MaintenancePage implements OnInit, OnDestroy {
    subscriptions = new Subscription();
    constructor(
      public router: Router,
      public route: ActivatedRoute,
      private commonService: CommonService,
    ) { }
    ngOnDestroy(): void {}
    async ngOnInit() {
      const isApiOk = await this.healthCheck();
      this.route.queryParams.subscribe(params => {
        if (isApiOk) {
          if (params['from']) {
            this.router.navigate([params['from']]);
          }
          else {
            this.router.navigate(['/404']);
          }
        }
      })
    }
    async healthCheck() {
      try {
        const response = await this.commonService.healthCheck().toPromise();
        if (response.status === "error") {
          return false
        }
      } catch (err) {
        return false
      }
      return true
    }
  }