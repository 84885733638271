import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import Session from 'src/app/models/session.model';
import Widget from 'src/app/models/widget.model';
import { SessionsService, TimeLineService } from 'src/app/providers';
import { SetKeysGlobal } from 'src/app/store/actions/global.actions';
import { GlobalState } from 'src/app/store/states/global.state';


@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.html',
  styleUrls: ['./date-selection.scss']
})
export class DateSelectionPage implements OnInit, OnDestroy {
  private _subscriber = new Subscription();
  public selectedDate: Date;
  public sessionsToSelect: Session[];
  public sessions: Session[];
  public dateChange$ = new Subject();
  public widgetId: string;
  public withSeatingPlan: boolean = false;

  constructor(
    private timeLineService: TimeLineService,
    private store: Store,
    private sessionsService: SessionsService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  ngOnInit(): void {
    this.timeLineService.updateTimeLine('dateSelection');
    const widget: Widget = this.store.selectSnapshot(state => GlobalState.get(state, 'widget'));

    if (widget.eventId.useRoomPlacement) {
      this.withSeatingPlan = true;
    }

    this.widgetId = widget._id;
    const settings = {
      filter: { eventId: widget.eventId._id },
      select: ['date', 'id', 'stock', 'ticketTypes', 'quota']
    };
    this._subscriber.add(this.sessionsService.getList(settings).subscribe(res => {
      if (res?.data) {
        const now = new Date().getTime();
        this.sessions = res.data.map((session: Session) => ({ ...session, date: new Date(session.date) }))
          .filter((session: Session) => session.date.getTime() > now);
        this.sessions.sort((a, b) => a.date.getTime() - b.date.getTime());
      }
    }));
  }

  onDateSelection(date) {
    this.selectedDate = date;
    if (date) {
      const dateAfter = new Date(date);
      dateAfter.setDate(date.getDate() + 1)
      this.sessionsToSelect = this.sessions.filter(session => {
        const sessionTime = new Date(session.date).getTime();
        return date.getTime() <= sessionTime && sessionTime < dateAfter.getTime();
      })
      this.dateChange$.next();
    }
  }

  goToTicketPage(session) {
    this.store.dispatch(new SetKeysGlobal({ value: session, key: 'session' }));
    if (this.withSeatingPlan) {
      this.router.navigate([`${this.widgetId}/seat-selection`]);
    } else {
      this.router.navigate([`${this.widgetId}/tickets-list`]);
    }
  }

}
