import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

// HTTP MODULE
import { Interceptor } from './etc/interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// QueryParamsTranslator
import { QueryParams } from './etc/queryParams';

// local storage wrapper
import { LocalStorageWrapper } from './etc/localStorageWrapper';

// Pages
import { InitPage } from './pages/init-page/init-page';
import { InsurancePage } from './pages/insurance/insurance';

import { TicketsListPage } from './pages/tickets-list/tickets-list';

import { OrderSummaryPage } from './pages/order-summary/order-summary';
import { ContactInformationPage } from './pages/contact-information/contact-information';
import { SummaryPage } from './pages/summary/summary';
import { ClosedPage } from './pages/closed/closed';

import { ConfirmationPage } from './pages/confirmation/confirmation';
import { DateSelectionPage } from './pages/date-selection/date-selection';
import { RegistrationPage } from './pages/registration/registration';

import { NotFoundPage } from './pages/not-found/not-found';
import { NotAuthorizedPage } from './pages/not-authorized/not-authorized';
import { SeatSelectionPage } from './pages/seat-selection/seat-selection';

// Providers
import {
  EventEmitterService,
  EventWidgetService,
  TicketService,
  TicketTypeService,
  TicketTypeCategoryService,
  TicketFormService,
  FormFieldService,
  FileService,
  PromoCodeService,
  AuthService,
  TimeLineService,
  OrderService,
  PromiserService,
  SessionsService,
  CurrencyService,
  PopInService,
  EventService,
  MediaService,
  AddonService,
  WalletService
} from './providers/index';
import { OHeaderComponent } from './components/organisms/o-header/o-header.component';
import { OResendComponent } from './components/organisms/o-resend/o-resend.component';
import { MTimelineComponent } from './components/molecules/m-timeline/m-timeline.component';
import { ALogoComponent } from './components/atoms/a-logo/a-logo.component';
import { ABoxCloseCountdownComponent } from './components/atoms/a-box-close-countdown/a-box-close-countdown.component';
import { OSummaryComponent } from './components/organisms/o-summary/o-summary.component';
import { TContentWSidebarComponent } from './components/templates/t-content-w-sidebar/t-content-w-sidebar.component';
import { LDefaultComponent } from './components/layout/l-default/l-default.component';
import { AButtonComponent } from './components/atoms/a-button/a-button.component';
import { ALoaderComponent } from './components/atoms/a-loader/a-loader.component';

import { ATitleMainComponent } from './components/atoms/a-title-main/a-title-main.component';
import { OPaymentBoxComponent } from './components/organisms/o-payment-box/o-payment-box.component';
import { OConfirmationComponent } from './components/organisms/o-confirmation/o-confirmation.component';
import { TContentCenterComponent } from './components/templates/t-content-center/t-content-center.component';
import { MContentSectionComponent } from './components/molecules/m-content-section/m-content-section.component';
import { MBannerSeatSelectionComponent } from './components/molecules/m-banner-seat-selection/m-banner-seat-selection.component';
import { MCardRateComponent } from './components/molecules/m-card-rate/m-card-rate.component';
import { AQuantitySelectorComponent } from './components/atoms/a-quantity-selector/a-quantity-selector.component';

// Locale
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeEnExtra from '@angular/common/locales/extra/en';
import { ODateSelectionBoxComponent } from './components/organisms/o-date-selection-box/o-date-selection-box.component';
import { OCalendarMonthComponent } from './components/organisms/o-calendar-month/o-calendar-month.component';
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localeEn, 'en', localeEnExtra);
/* states */

import { GlobalState } from './store/states/global.state';
import { MCategoryAccordionItemComponent } from './components/molecules/m-category-accordion-item/m-category-accordion-item.component';
import { MFormRadioListComponent } from './components/molecules/m-form-radio-list/m-form-radio-list.component';
import { MFormCheckboxListComponent } from './components/molecules/m-form-checkbox-list/m-form-checkbox-list.component';
import { AFormCheckboxRadioFieldComponent } from './components/atoms/a-form-checkbox-radio-field/a-form-checkbox-radio-field.component';
import { AFormToggleFieldComponent } from './components/atoms/a-form-toggle-field/a-form-toggle-field.component';
import { AFormInputFieldComponent } from './components/atoms/a-form-input-field/a-form-input-field.component';
import { MFormRowComponent } from './components/molecules/m-form-row/m-form-row.component';
import { AFormSelectFieldComponent } from './components/atoms/a-form-select-field/a-form-select-field.component';
import { MFormRowContainerComponent } from './components/molecules/m-form-row-container/m-form-row-container.component';
import { MFormTicketAccordionItemComponent } from './components/molecules/m-form-ticket-accordion-item/m-form-ticket-accordion-item.component';
import { AFormTitleSectionComponent } from './components/atoms/a-form-title-section/a-form-title-section.component';
import { MMessageBoxComponent } from './components/molecules/m-message-box/m-message-box.component';
import { OSummaryTableComponent } from './components/organisms/o-summary-table/o-summary-table.component';
import { OSummarySeatSelectionComponent } from './components/organisms/o-summary-seat-selection/o-summary-seat-selection.component';
import { ACategorySeatItemComponent } from './components/atoms/a-category-seat-item/a-category-seat-item.component';
import { MCategorySeatSummaryItemComponent } from './components/molecules/m-category-seat-summary-item/m-category-seat-summary-item.component';
import { MCouponAddComponent } from './components/molecules/m-coupon-add/m-coupon-add.component';
import { OSoldoutComponent } from './components/organisms/o-soldout/o-soldout.component';
import { AFormTextareaFieldComponent } from './components/atoms/a-form-textarea-field/a-form-textarea-field.component';
import { AFormScoreFieldComponent } from './components/atoms/a-form-score-field/a-form-score-field.component';
import { AFormFileFieldComponent } from './components/atoms/a-form-file-field/a-form-file-field.component';
import { OPopInComponent } from './components/organisms/o-pop-in/o-pop-in.component';
import { CustomTranslationPipe } from './etc/custom-translation/custom-translation.pipe';
import { FilesHandler } from './etc/files-handler';
import { LocalizedDatePipe } from './etc/localized-date/localized-date.pipeline';
import { NotFromBarGuard } from './etc/guard/not-from-bar.guard';
import { ATooltipComponent } from './components/atoms/a-tooltip/a-tooltip.component'
import { MCountdownComponent } from './components/molecules/m-countdown/m-countdown.component'
import { MPlaceLeftComponent } from './components/molecules/m-place-left/m-place-left.component'

@NgModule({
  declarations: [
    AppComponent,

    // Pages
    InitPage,
    InsurancePage,
    TicketsListPage,
    OrderSummaryPage,
    ContactInformationPage,
    SummaryPage,
    ConfirmationPage,
    ClosedPage,
    DateSelectionPage,
    RegistrationPage,
    NotFoundPage,
    NotAuthorizedPage,
    OHeaderComponent,
    MTimelineComponent,
    MCountdownComponent,
    MPlaceLeftComponent,
    ALogoComponent,
    ABoxCloseCountdownComponent,
    OSummaryComponent,
    TContentWSidebarComponent,
    LDefaultComponent,
    AButtonComponent,
    ALoaderComponent,
    ATitleMainComponent,
    OPaymentBoxComponent,
    OConfirmationComponent,
    TContentCenterComponent,
    MContentSectionComponent,
    MBannerSeatSelectionComponent,
    MCardRateComponent,
    AQuantitySelectorComponent,
    ODateSelectionBoxComponent,
    OCalendarMonthComponent,
    MCategoryAccordionItemComponent,
    MFormRadioListComponent,
    MFormCheckboxListComponent,
    AFormCheckboxRadioFieldComponent,
    AFormToggleFieldComponent,
    AFormInputFieldComponent,
    MFormRowComponent,
    AFormSelectFieldComponent,
    MFormRowContainerComponent,
    MFormTicketAccordionItemComponent,
    AFormTitleSectionComponent,
    MMessageBoxComponent,
    OSummaryTableComponent,
    SeatSelectionPage,
    OSummarySeatSelectionComponent,
    ACategorySeatItemComponent,
    MCategorySeatSummaryItemComponent,
    MCouponAddComponent,
    OSoldoutComponent,
    AFormTextareaFieldComponent,
    AFormScoreFieldComponent,
    AFormFileFieldComponent,
    OPopInComponent,
    OResendComponent,
    CustomTranslationPipe,
    LocalizedDatePipe,
    ATooltipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    TextareaAutosizeModule,
    NgxsModule.forRoot([GlobalState])
  ],
  providers: [
    // HTTP Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    // Query params translator
    QueryParams,
    // local storage wrapper
    LocalStorageWrapper,
    // Services
    EventEmitterService,
    EventWidgetService,
    TicketService,
    TicketTypeService,
    TicketTypeCategoryService,
    TicketFormService,
    FormFieldService,
    FileService,
    PromoCodeService,
    AuthService,
    TimeLineService,
    OrderService,
    PromiserService,
    SessionsService,
    CurrencyService,
    PopInService,
    EventService,
    MediaService,
    FilesHandler,
    AddonService,
    WalletService,
    [NotFromBarGuard],
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
